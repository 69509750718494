import { Phone } from '@mui/icons-material';
import React from 'react';
import PhoneView from './PhoneView';
import { Box } from '@mui/material';
import Storee from './Store';

const About = () => {
    return (
        <div>
        <Box sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
            <PhoneView />
         
        </Box>
        <Storee/>
        </div>
    );
};

export default About;