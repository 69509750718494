import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import playStoreImage from '../assests/playsyore-removebg-preview.png'; // Update the path to your Play Store image
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    image: {
        width: '150px',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
});

const Storee = () => {
    const classes = useStyles();

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom>
                Download Our App
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                <Grid item>
                    <a href="https://play.google.com/store/apps/details?id=org.Mirusoft.gpsonline" target="_blank" rel="noopener noreferrer">
                        <img src={playStoreImage} alt="Play Store" className={classes.image} />
                    </a>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Storee;