import React, { useEffect, useState } from 'react';
import { Box, Grid2, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import {
  DeviceThermostat,
  AccountTree,
  PhoneAndroid,
  History,
  InsertChartOutlined,
  Event,
  ControlCamera,
  DevicesOther,
  Map,
  Language,
  SupportAgent,
  NotificationsActive,
  Build,
  Link,
  Api,
} from '@mui/icons-material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS

// Styled Paper component for individual feature cards
const FeatureCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  textAlign: 'center',
  background: '#f9f9f9',
  borderRadius: '12px',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.2)',
  },
}));

// Icons for each feature
const FeatureIcons = {
  'Track Devices': <DeviceThermostat sx={{ fontSize: 60, color: 'purple' }} />,
  'Retailer Friendly': <SupportAgent sx={{ fontSize: 60, color: 'purple' }} />,
  'Sub-Accounts': <AccountTree sx={{ fontSize: 60, color: 'purple' }} />,
  'Android and iOS Apps': <PhoneAndroid sx={{ fontSize: 60, color: 'purple' }} />,
  '45 Days History': <History sx={{ fontSize: 60, color: 'purple' }} />,
  Reports: <InsertChartOutlined sx={{ fontSize: 60, color: 'purple' }} />,
  Events: <Event sx={{ fontSize: 60, color: 'purple' }} />,
  'Pick and Drop Alerts': <NotificationsActive sx={{ fontSize: 60, color: 'purple' }} />,
  'Maintenance Alerts': <Build sx={{ fontSize: 60, color: 'purple' }} />,
  'API Support': <Api sx={{ fontSize: 60, color: 'purple' }} />,
  'Tracking Link': <Link sx={{ fontSize: 60, color: 'purple' }} />,
  '900+ Supported Devices': <DevicesOther sx={{ fontSize: 60, color: 'purple' }} />,
  'More Maps': <Map sx={{ fontSize: 60, color: 'purple' }} />,
  '90 Days History': <History sx={{ fontSize: 60, color: 'purple' }} />,
  'Multilingual Interface': <Language sx={{ fontSize: 60, color: 'purple' }} />,
};

const Cards = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    setIsLoaded(true); // Simulate content loading (You can replace this with actual data loading)
  }, []);

  const features = [
    { title: 'Track Devices', description: 'Track your devices in real-time on the map.' },
    { title: 'Retailer Friendly', description: 'Retailers can easily manage business easily' },
    { title: 'Sub-Accounts', description: 'Connect unlimited sub accounts. Create multiple sub accounts for your customers.' },
    { title: 'Android and iOS Apps', description: 'Easily access accounts and make the device a GPS tracker.' },
    { title: '45 Days History', description: 'History is kept for 45 days.' },
    { title: 'Reports', description: 'Request instant or set scheduled reports.' },
    { title: 'Events', description: 'Instantly receive push or e-mail' },
    { title: 'Pick and Drop Alerts', description: 'Get alerts when the Vehicle is nearing picked up or dropped off point. Ideal for School, Colleges and Institution' },
    { title: 'Maintenance Alerts', description: 'Get alerts for vehicle maintenance. Fitness Control(FC), Insurance, Pollution, Oil Service' },
    { title: 'API Support', description: 'Integrate our Api with your Management software to get real time data.' },
    { title: "Tracking Link", description: 'Share tracking link with your customers to track your vehicle, ideal for busses and Load Trucks.' },
    { title: '900+ Supported Devices', description: 'Wide range of supported GPS trackers.' },
    { title: 'More Maps', description: 'Google Streets, Satellite, Hybrid, Terrain, Traffic, and Street View.' },
    { title: '90 Days History', description: 'Extended history period to 90 days.' },
    { title: 'Multilingual Interface', description: 'Software is translated to more than 36 languages.' },
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" sx={{ marginBottom: 4, fontWeight: 'bold', color: '#333' }} data-aos="fade-up">
        {isLoaded ? 'Key Features' : <Skeleton width={200} />}
      </Typography>

      <Grid2 container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid2 item xs={12} sm={6} md={4} key={index} data-aos="fade-up" data-aos-delay={index * 100}>
            <FeatureCard>
              {/* Show skeleton while loading */}
              {isLoaded ? FeatureIcons[feature.title] : <Skeleton circle width={60} height={60} />}
              
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, color: '#333' }}>
                {isLoaded ? feature.title : <Skeleton width={150} />}
              </Typography>

              <Typography variant="body2" sx={{ color: '#777', marginTop: 1 }}>
                {isLoaded ? feature.description : <Skeleton count={2} />}
              </Typography>
            </FeatureCard>
          </Grid2>
        ))}
      </Grid2>

      <Box sx={{ marginTop: 5, textAlign: 'center' }} data-aos="fade-up">
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold', color: '#333' }}>
          {isLoaded ? 'Try our full-featured service for 14 days without any limits.' : <Skeleton width={250} />}
        </Typography>
        <Typography variant="body2" sx={{ color: '#777', marginBottom: 3 }}>
          {isLoaded ? 'Just follow the link to ' : <Skeleton count={2} />}
          {isLoaded && <a href="https://app.gpsonline.in" style={{ color: 'purple', textDecoration: 'none' }}>https://app.gpsonline.in</a>}
        </Typography>
      </Box>
    </Box>
  );
};

export default Cards;
