import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Heroimg from '../assests/heroimg.jpg'; // Your Hero Image
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styled Hero Section
const HeroSection = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: `url(${Heroimg}) no-repeat center center/cover`,
  padding: theme.spacing(4),
  color: 'white',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Slight dark overlay for text contrast
  textAlign: 'center',
  transition: 'background 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Darken on hover
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    paddingTop: theme.spacing(8),
  },
}));

// Hero Content Wrapper
const HeroContent = styled('div')(({ theme }) => ({
  maxWidth: '700px',
  margin: '0 auto',
  zIndex: 2,
  textAlign: 'center',
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slight background to make text more legible
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

// Hero Title (Main Heading)
const HeroTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '4.5rem',
  letterSpacing: '3px',
  textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
  color: '#fff', // Ensure title is clear
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}));

// Hero Text (Subheading)
const HeroText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: '1.4rem',
  fontWeight: '500',
  color: '#fff', // Ensure text is legible
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  },
}));

// Styled Buttons
const HeroButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: 4,
  padding: theme.spacing(1.5, 3),
  fontWeight: 600,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: 'purple', // Change button hover color
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2),
  },
}));

// Additional Feature Container
const FeatureContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: theme.spacing(4),
  borderRadius: 8,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const BackHomeButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2), // changed from left to right
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#1976d2',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: '#fff',
    transform: 'scale(1.05)',
  },
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  borderRadius: 8, // added border radius
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)', // enhanced box shadow
}));

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoaded, setIsLoaded] = useState(false);

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    setIsLoaded(true);
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <HeroSection ref={ref}>
      {/* Add Back Home Button */}
     

      <HeroContent>
        <motion.div initial="hidden" animate={controls} variants={variants}>
          {/* Hero Title */}
          <HeroTitle variant="h2" component="h1" gutterBottom>
            {isLoaded ? 'Free Vehicle Tracking' : <Skeleton width={300} />}
          </HeroTitle>
          
          {/* Hero Text */}
          <HeroText variant="h6" paragraph>
            {isLoaded 
              ? 'Track your vehicle in real-time, anywhere, anytime. Stay secure and have peace of mind with our easy-to-use GPS tracking system.' 
              : <Skeleton count={3} />
            }
          </HeroText>

          {/* Start Free Trial Button */}
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.7, ease: 'easeOut' }}
          >
            {isLoaded 
              ? <HeroButton variant="contained" color="primary" size="large" href='https://app.gpsonline.in/'>Start Free Trial</HeroButton>
              : <Skeleton width={200} height={48} />
            }
          </motion.div>

          {/* Contact Us Button */}
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.7, ease: 'easeOut', delay: 0.2 }}
          >
            {isLoaded 
              ? <HeroButton variant="outlined" color="primary" size="large" href='/contact' >Contact Us</HeroButton>
              : <Skeleton width={200} height={48} />
            }
          </motion.div>
        </motion.div>
      </HeroContent>

      {/* Additional Features Section */}
      <FeatureContainer>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
                {isLoaded ? 'Real-Time GPS Tracking' : <Skeleton width={150} />}
              </Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {isLoaded 
                  ? 'Track your vehicle\'s location in real time with our advanced GPS technology.'
                  : <Skeleton count={2} />
                }
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.9 }}
            >
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
                {isLoaded ? 'Easy Access Anytime' : <Skeleton width={150} />}
              </Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {isLoaded 
                  ? 'Access your vehicle\'s location and history data with a simple, user-friendly app.'
                  : <Skeleton count={2} />
                }
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </FeatureContainer>
    </HeroSection>
  );
};

export default Hero;
