import React, { useState } from "react";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Right-pointing arrow
import AddIcon from "@mui/icons-material/Add";

const FloatingIcons = () => {
  const [showIcons, setShowIcons] = useState(false);  // State to toggle icon visibility

  const toggleIcons = () => {
    setShowIcons((prev) => !prev);  // Toggle the visibility of the icons
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",  // Center vertically
        right: "0",  // Positioning from the right
        transform: "translateY(-50%)",  // Adjust for perfect centering
        display: "flex",
        flexDirection: "column",  // Stack icons vertically
        gap: "10px",  // Space between icons
        alignItems: "center", // Center align the icons
        zIndex: 9999,  // Ensure it's on top of all other elements
      }}
    >
      {/* Arrow Button to show/hide the icons */}
      {!showIcons && (
        <IconButton
          color="primary"
          style={{
            backgroundColor: "#1976D2",  // Color for the arrow button
            color: "white",
            borderRadius: "50%",
            marginBottom: "10px",  // Space between the arrow and icons
            zIndex: 9999,  // Ensure the arrow button stays on top
          }}
          onClick={toggleIcons}
        >
         <AddIcon /> {/* Right-pointing arrow */}
        </IconButton>
      )}

      {/* Icons to be shown when the arrow button is clicked */}
      {showIcons && (
        <>
          {/* WhatsApp Icon */}
         

          {/* Phone Icon */}
          <IconButton
            color="inherit"
            href="tel:+919597088261"  // Replace with your phone number
            style={{
              backgroundColor: "#4CAF50", 
              color: "white",
              zIndex: 9999,  // Ensure the icon stays on top
            }}
          >
            <PhoneIcon />
          </IconButton>

          {/* Email Icon */}
          <IconButton
            color="inherit"
            href="mailto:mirusoft24@gmail.com"  // Replace with your email address
            style={{
              backgroundColor: "#1976D2", 
              color: "white",
              zIndex: 9999,  // Ensure the icon stays on top
            }}
          >
            <EmailIcon />
          </IconButton>

          {/* Facebook Icon */}
          
           
         
        </>
      )}
    </div>
  );
};

export default FloatingIcons;
