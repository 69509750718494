import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import Hero from './Hero';
import Cards from './Cards';

import InitialOfferBanner from './InitialOfferBanner';
import Storee from './Store';

const Home = () => {
   

    return (
        <div>
      <InitialOfferBanner/>
        <Hero/>
        <Cards/>
       <Storee/>
        </div>
        
    );
};

export default Home;