import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { CheckCircle, ErrorOutline, Info } from '@mui/icons-material';
import Storee from './Store';

const GettingStarted = () => {
  return (
    <div>
    <Box sx={{ backgroundColor: '#fff', padding: '40px', minHeight: '100vh' }}>
      <Typography variant="h3" sx={{ fontWeight: '700', marginBottom: '20px', color: '#2C3E50' }}>
        Getting Started with GPS Tracking
      </Typography>

      <Typography variant="body1" paragraph sx={{ fontSize: '16px', lineHeight: '1.6', color: 'rgba(127, 140, 141, 0.8)' }}>
        To get access to all available features, create a fully functional account and start tracking now. Use
        GPS server completely free, no questions asked. All devices connected to <a href="https://app.gpsonline.in/" target="_blank" style={{ color: 'pruple', textDecoration: 'none' }}>https://gps.online.in/</a> servers will work worldwide.
      </Typography>

      <Typography variant="h5" sx={{ marginTop: '40px', fontWeight: '600', color: '#34495e' }}>
        Instructions on How to Create an Account, Configure It, and Connect a Tracking Device
      </Typography>

      {/* Account Configuration Section */}
      <Typography variant="h6" sx={{ marginTop: '30px', fontWeight: '500', color: '#34495e' }}>
        Account Configuration
      </Typography>
      <List sx={{ marginTop: '10px' }}>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Make sure that your device is listed on the supported devices page." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Create an account by entering your email address; credentials will be delivered to the provided email address." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Add device IMEI in settings." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Set your country time zone in account settings." />
        </ListItem>
      </List>

      {/* Device Configuration Section */}
      <Typography variant="h6" sx={{ marginTop: '40px', fontWeight: '500', color: '#34495e' }}>
        Device Configuration
      </Typography>
      <Typography variant="body1" paragraph sx={{ marginTop: '10px', fontSize: '16px', lineHeight: '1.6', color: 'rgba(127, 140, 141, 0.8)' }}>
        If you do not have a dedicated GPS tracking device, use the free GPS Tracker application for Android and iOS to track smartphones.
      </Typography>
      <Typography variant="body1" paragraph sx={{ marginTop: '10px', fontSize: '16px', lineHeight: '1.6', color: 'rgba(127, 140, 141, 0.8)' }}>
        If you have a dedicated GPS tracking device, follow the steps below:
      </Typography>
      <List sx={{ marginTop: '10px' }}>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Study the device manual for instructions on how to set server IP, PORT, and APN for your specific device." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="The IP and PORT that must be set on the device side can be found on the supported devices page." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Configure APN on the device side. APN settings should be provided by your SIM card provider. Check if a username/password is required for the APN." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Set the GPS device time zone to 0 UTC; otherwise, it will show incorrect time or events won’t work." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Make sure that the SIM card has enough credits for Internet." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Test the device outside while driving to ensure a good GPS signal." />
        </ListItem>
        <ListItem>
          <CheckCircle sx={{ color: '#2ecc71', marginRight: '12px' }} />
          <ListItemText primary="Device location should appear in your account within minutes." />
        </ListItem>
      </List>

      {/* Troubleshooting Section */}
      <Typography variant="h6" sx={{ marginTop: '40px', fontWeight: '500', color: '#34495e' }}>
        Troubleshooting
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontSize: '16px', lineHeight: '1.6', color: 'rgba(127, 140, 141, 0.8)' }}>
        If, after following all the instructions, the device refuses to connect to the server, check the points below:
      </Typography>
      <List sx={{ marginTop: '10px' }}>
        <ListItem>
          <ErrorOutline sx={{ color: '#e74c3c', marginRight: '12px' }} />
          <ListItemText primary="Ensure you used the correct method to set server IP, PORT, and APN. For assistance, contact the device seller or manufacturer." />
        </ListItem>
        <ListItem>
          <ErrorOutline sx={{ color: '#e74c3c', marginRight: '12px' }} />
          <ListItemText primary="Test the device outside to receive a strong GPS signal. GPS signal absence will lead to connection problems. Tall buildings nearby also affect GPS quality. Test the device in an open field." />
        </ListItem>
        <ListItem>
          <ErrorOutline sx={{ color: '#e74c3c', marginRight: '12px' }} />
          <ListItemText primary="If all troubleshooting points are confirmed but there's still no connection, the device might use a different communication protocol which requires another PORT. Contact us to determine the proper PORT for device connection." />
        </ListItem>
      </List>

      {/* Online Demo Section */}
      <Typography variant="h6" sx={{ marginTop: '40px', fontWeight: '500', color: '#34495e' }}>
        Online Demo
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontSize: '16px', lineHeight: '1.6', color: 'rgba(127, 140, 141, 0.8)' }}>
        A demo account allows you to see moving devices which were created virtually to represent a real-life scenario.
        The demo account is limited and doesn’t allow changes; it can be used only for viewing purposes.
        Visit the demo account to explore its features.
      </Typography>
    </Box>
    <Storee/>
    </div>
  );
};

export default GettingStarted;
