import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import {
  Step,
  StepLabel,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import StarIcon from '@mui/icons-material/Star';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import InfoIcon from '@mui/icons-material/Info';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const Pay = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    PlansOption: "",
  });

  const [formErrors, setFormErrors] = useState({
    PlansOption: false,
  });

  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState({
    basic: [],
    pro: []
  });
  const navigate = useNavigate();

  const steps = ["Plans Options"];

  const plans = [
    {
      value: "basic",
      label: "Basic Plan",
      price: "33Rs",
      description: "Ideal for Personal/fleet management",
      popular: true,
      offer: "SAVE 20% for bulk orders",
      features: [
        "Real-time location updates",
        "Geofencing alerts",
        "Mobile and web app access",
        "Priority customer support",
        "Advanced analytics",
        "vehicle diagnostics and reports",

      ],
      vehiclePricing: {
        "1 vehicle": "500/year",
        "5 vehicles": "2400/year",
        "10 vehicles": "4500/year",
        "25 vehicles": "10500/year",
        "50 vehicles": "20000/year",
      },
    },
    {
      value: "pro",
      label: "Pro Plan",
      price: "40Rs",
      description: "Ideal for Institutions/Logistics Enterprises",
      popular: false,
      offer: "SAVE 20% for bulk orders",
      features: [
        "Real-time location updates",
        "Advanced geofencing alerts",
        "Vehicle diagnostics and reports",
     
        "Advanced analytics",
        "Mobile and web app access",
        "Priority customer support",
       
      ],
      extrafeatures: [
        "API access and Custom APP integrations",
        "Multi Accounts pickup and drop alerts eg:Schools, Colleges and Institutions",
      ],
      vehiclePricing: {
        "1 vehicle": "600/year",
        "5 vehicles": "2880/year",
        "10 vehicles": "5400/year",
        "25 vehicles": "12750/year",
        "50 vehicles": "24000/year",
      },
    },
  ];

  const [selectionStatus, setSelectionStatus] = useState({
    planSelected: false,
    vehicleSelected: false
  });

  const handlePlanSelect = (plan) => {
    setSelectedPlan(prevPlan => {
      const newPlan = prevPlan === plan.value ? "" : plan.value;
      setSelectionStatus(prev => ({
        ...prev,
        planSelected: !!newPlan
      }));
      return newPlan;
    });
    
    // Clear vehicle selections when changing plan
    setSelectedVehicles({
      basic: [],
      pro: []
    });
    setSelectionStatus(prev => ({
      ...prev,
      vehicleSelected: false
    }));
  };

  const handleVehicleSelect = (planValue, vehicle, price) => {
    setSelectedVehicles((prev) => {
      const isCurrentlySelected = prev[planValue]?.some(item => item.vehicle === vehicle);
      const newSelection = {
        ...prev,
        [planValue]: isCurrentlySelected ? [] : [{ vehicle, price }]
      };
      
      setSelectionStatus(prev => ({
        ...prev,
        vehicleSelected: !!newSelection[planValue].length
      }));
      
      return newSelection;
    });
  };

  const handleSubmit = () => {
    if (selectedPlan && selectedVehicles[selectedPlan].length > 0) {
      // Find the selected plan details
      const planDetails = plans.find(plan => plan.value === selectedPlan);
      
      navigate("/contact", { 
        state: { 
          selectedPlan: planDetails.label, // Pass the plan label
          selectedVehicles: selectedVehicles[selectedPlan]
        } 
      });
    }
  };

  const EmptySelectionState = () => (
    <Box
      sx={{
        textAlign: 'center',
        py: 3,
        px: 2,
        bgcolor: '#f5f5f5',
        borderRadius: 2,
        border: '1px dashed #bdbdbd',
        mb: 2
      }}
    >
      {!selectionStatus.planSelected ? (
        <Box>
          <InfoIcon sx={{ fontSize: 40, color: '#757575', mb: 1 }} />
          <Typography variant="h6" color="textSecondary">
            Please Select a Plan First
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Choose between Basic or Pro plan to view vehicle options
          </Typography>
        </Box>
      ) : !selectionStatus.vehicleSelected ? (
        <Box>
          <DirectionsCarIcon sx={{ fontSize: 40, color: '#757575', mb: 1 }} />
          <Typography variant="h6" color="textSecondary">
            Select Number of Vehicles
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Choose how many vehicles you want to track
          </Typography>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <Box sx={{ paddingTop: "40px" }}>
        {activeStep === 0 && (
          <div>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{ fontWeight: 700, color: "#2C387E", marginBottom: 3 }}
            >
              Choose Your Plan
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              sx={{ marginBottom: 4 }}
            >
              Select a plan that fits your needs. 
            </Typography>

            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="flex-start"
            >
              {plans.map((plan) => (
                <Grid item xs={12} sm={6} md={3} key={plan.value}>
                  <Card
                    sx={{
                      borderRadius: 3,
                      boxShadow: 6,
                      position: "relative",
                      overflow: "visible",
                      transition:
                        "transform 0.3s ease, box-shadow 0.3s ease",
                      border: plan.value === selectedPlan 
                        ? "3px solid #4caf50" 
                        : plan.popular 
                          ? "3px solid #ff6f00" 
                          : "1px solid #e0e0e0", // Added default border
                      cursor: "pointer", // Added cursor pointer
                      backgroundColor: selectedPlan === plan.value ? "#e8f5e9" : "white", // Highlight selected
                      "&:hover": {
                        transform: "translateY(-10px)",
                        boxShadow: "0px 12px 40px rgba(0, 0, 0, 0.2)",
                      },
                      height: "100%",
                    }}
                    onClick={() => handlePlanSelect(plan)}
                  >
                    {plan.popular && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: -12,
                          right: 20,
                          background: "#ff6f00",
                          color: "white",
                          padding: "6px 14px",
                          borderRadius: "12px",
                          fontSize: "0.75rem",
                          fontWeight: 600,
                          zIndex: 1,
                        }}
                      >
                        MOST POPULAR
                      </Box>
                    )}
                    {plan.offer && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 15,
                          left: -10,
                          background: "#3f51b5",
                          color: "white",
                          padding: "4px 12px",
                          borderRadius: "0 4px 4px 0",
                          fontSize: "0.75rem",
                          fontWeight: "bold",
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            bottom: -8,
                            borderTop: "8px solid #283593",
                            borderLeft: "8px solid transparent",
                          },
                        }}
                      >
                        {plan.offer}
                      </Box>
                    )}
                    <CardActionArea>
                      <CardContent sx={{ padding: 4 }}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ fontWeight: 700, color: "#2C387E" }}
                        >
                          {plan.label}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ marginBottom: 3, color: "#555" }}
                        >
                          {plan.description}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 700,
                            color: "#2C387E",
                            marginBottom: 3,
                          }}
                        >
                          ₹{plan.price}
                          <span style={{ fontSize: "1rem", color: "#777" }}>
                            /Month/Device 
                          </span>
                          <span style={{ fontSize: "1rem", color: "#777" }}><br/>billed annually</span>
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        <List sx={{ paddingLeft: 0 }}>
                          {plan.features.map((feature, index) => (
                            <ListItem key={index} sx={{ padding: "8px 0" }}>
                              <ListItemIcon>
                                <CheckCircleIcon
                                  sx={{ color: "#4caf50" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  feature === "api access" ||
                                  feature === "pickup and drop alerts" ||
                                  feature === "Pulk Tracking eg:Schools" ? (
                                    <span
                                      style={{
                                        color: "#ff5722",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {feature}
                                    </span>
                                  ) : (
                                    feature
                                  )
                                }
                                sx={{ color: "#777" }}
                              />
                            </ListItem>
                          ))}
                        </List>

                        {/* Add Extra Features section for Pro plan */}
                        {plan.value === 'pro' && plan.extrafeatures && (
                          <>
                            <Box
                              sx={{
                                mt: 3,
                                mb: 3,
                                position: 'relative',
                                border: '2px solid #ff5722',
                                borderRadius: 2,
                                p: 2,
                                bgcolor: 'rgba(255, 87, 34, 0.03)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.15)',
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: -12,
                                  left: 16,
                                  bgcolor: '#fff',
                                  px: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <StarIcon sx={{ color: '#ff5722' }} />
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 700,
                                    color: '#ff5722',
                                  }}
                                >
                                  Premium Features
                                </Typography>
                              </Box>

                              <Box sx={{ mt: 1 }}>
                                {plan.extrafeatures.map((feature, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                      mb: 1.5,
                                      p: 1.5,
                                      bgcolor: '#fff',
                                      borderRadius: 1,
                                      border: '1px solid rgba(255, 87, 34, 0.2)',
                                      transition: 'all 0.3s ease',
                                      '&:hover': {
                                        transform: 'translateX(8px)',
                                        bgcolor: '#fff3e0',
                                        borderColor: '#ff5722',
                                      },
                                    }}
                                  >
                                    <LightbulbIcon sx={{ color: '#ff5722' }} />
                                    <Typography
                                      sx={{
                                        color: '#ff5722',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                      }}
                                    >
                                      {feature}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </>
                        )}

                        {/* Vehicle Pricing */}
                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 600, color: "#2C387E" }}
                        >
                          Vehicle Pricing
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                          {Object.entries(plan.vehiclePricing).map(([vehicle, price]) => (
                            <ListItem key={vehicle} sx={{ padding: "8px 0" }}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={selectedVehicles[plan.value]?.some(
                                    (item) => item.vehicle === vehicle
                                  )}
                                  onChange={() => handleVehicleSelect(plan.value, vehicle, price)}
                                  onClick={(e) => e.stopPropagation()} // Prevent card click when clicking checkbox
                                  disabled={!selectedPlan || (selectedPlan !== plan.value)}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${vehicle}: ₹${price}`}
                                sx={{ color: "#777" }}
                              />
                            </ListItem>
                          ))}
                        </List>

                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 6,
              }}
            >
              {(!selectionStatus.planSelected || !selectionStatus.vehicleSelected) && (
                <EmptySelectionState />
              )}

              {selectionStatus.planSelected && selectionStatus.vehicleSelected && (
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    bgcolor: '#e3f2fd',
                    borderRadius: 2,
                    border: '1px solid #90caf9'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Your Selection
                  </Typography>
                  <Typography>
                    Plan: {selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1)} Plan
                  </Typography>
                  {selectedVehicles[selectedPlan]?.map((item, index) => (
                    <Typography key={index}>
                      Vehicles: {item.vehicle} - ₹{item.price}
                    </Typography>
                  ))}
                </Box>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!selectionStatus.planSelected || !selectionStatus.vehicleSelected}
                sx={{
                  padding: "12px 40px",
                  fontSize: "1.1rem",
                  fontWeight: 600,
                  borderRadius: 3,
                  textTransform: "none",
                  background:
                    "linear-gradient(45deg, #3f51b5 30%, #5c6bc0 90%)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #283593 30%, #3f51b5 90%)",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Continue"
                )}
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Pay;
