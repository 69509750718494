import React from 'react';
import SupportedDevice from './SupportedDevice';

const Services = () => {
    return (
        <div>
          <SupportedDevice />
        </div>
    );
};

export default Services;