import React from 'react';
import GettingStarted from './GettingsStarted';

const Blog = () => {
    return (
        <div>
           <GettingStarted/>
        </div>
    );
};

export default Blog;