import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, List, ListItem, Paper } from '@mui/material';
import { styled } from '@mui/system';
import phone1 from '../assests/phoneviewImage1.png';
import phone2 from '../assests/phoneviimage12.png';
import phone3 from '../assests/desktopview.png';

// Image sources
const images = {
  gpsServerMobile: phone1,
  gpsTracker: phone2,
  website: phone3,
};

// Styled components for cards and content
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: 12,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 250,
  objectFit: 'contain',
  borderRadius: '12px 12px 0 0',
  marginBottom: theme.spacing(2),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
}));

const FeatureList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const CardItem = ({ title, description, features, images }) => (
  <StyledCard>
    {/* Render images based on the number of images in the 'images' array */}
    <Grid container spacing={2}>
      {images.length === 1 ? (
        <Grid item xs={12}>
          <StyledCardMedia component="img" image={images[0]} alt={title} />
        </Grid>
      ) : (
        images.map((image, idx) => (
          <Grid item xs={6} key={idx}>
            <StyledCardMedia component="img" image={image} alt={title} />
          </Grid>
        ))
      )}
    </Grid>

    {/* Card content */}
    <StyledCardContent>
      <Typography variant="h6" sx={{ fontWeight: '600', color: 'purple', marginBottom: 2 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph sx={{ fontSize: '0.95rem' }}>
        {description}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: '600', mb: 1 ,color: 'purple'}}>
        Features:
      </Typography>
      <FeatureList dense>
        {features.map((feature, idx) => (
          <ListItem key={idx} sx={{ pl: 0 }}>
            <Typography variant="body2">• {feature}</Typography>
          </ListItem>
        ))}
      </FeatureList>
    </StyledCardContent>
  </StyledCard>
);


const cards = [
  {
    title: 'GPS Tracker Mobile',
    description: 'A simplified version of a fully-featured desktop account, providing real-time tracking and device control.',
    features: [
      'Compatible with Android and iOS devices',
      'Real-time location of connected devices',
      'Review history tracks for the last 45 days',
      'Receive instant push notifications',
      'Multilingual interface',
      'Retailers can easily manage business easily',
      'Connect unlimited sub accounts. Create multiple sub accounts for your customers.',
      'Easily access accounts and make the device a GPS tracker.',
      'Request instant or set scheduled reports.',
      'Instantly receive push or e-mail',
      'Get alerts when the Vehicle is nearing picked up or dropped off point. Ideal for School, Colleges and Institution',
      'Get alerts for vehicle maintenance. Fitness Control(FC), Insurance, Pollution, Oil Service',
      'Integrate our Api with your Management software to get real time data.',
      'Share tracking link with your customers to track your vehicle, ideal for busses and Load Trucks.',
      'Wide range of supported GPS trackers.',
      'Google Streets, Satellite, Hybrid, Terrain, Traffic, and Street View.',
      'Software is translated to more than 36 languages.',
    ],
    images: [images.gpsServerMobile, images.gpsTracker],
  },
  {
    title: 'Website',
    description: 'A responsive and feature-rich website that integrates seamlessly with your business operations.',
    features: [
      'Mobile-responsive design',
      'SEO-optimized for better visibility',
      'Customizable for your business needs',
      'Easy to manage and update content',
      'Multilingual interface',
      'Retailers can easily manage business easily',
      'Connect unlimited sub accounts. Create multiple sub accounts for your customers.',
      'Easily access accounts and make the device a GPS tracker.',
      'History is kept for 45 days.',
      'Request instant or set scheduled reports.',
      'Instantly receive push or e-mail',
      'Integrate our Api with your Management software to get real time data.',
      'Share tracking link with your customers to track your vehicle, ideal for busses and Load Trucks.',
      'Wide range of supported GPS trackers.',
      'Google Streets, Satellite, Hybrid, Terrain, Traffic, and Street View.',
      'Extended history period to 90 days.',
      'Software is translated to more than 36 languages.',
    ],
    images: [images.website],
  },
];


const PhoneView = () => (
  <Grid container spacing={4} justifyContent="center">
    {cards.map((card, index) => (
      <Grid item key={index} xs={12} sm={6} md={4}>
        <Paper sx={{ padding: 2 }}>
          <CardItem {...card} />
        </Paper>
      </Grid>
    ))}
  </Grid>
);

export default PhoneView;
