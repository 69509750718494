import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import NavHeader from './components/NavHeader';
import FloatingIcons from './components/FloatingIcons';
import Pay from './components/Pay';
import OTPComponent from './components/OTPComponent';
import { Plans } from '@mui/icons-material';
import { useEffect } from 'react';
import axios from 'axios';
import PrivacyPolicy from './components/PrivacyPolicy';


function App() {
  // useEffect(() => {
  //   // Extract the query parameters from the URL
  //   const params = new URLSearchParams(window.location.search);
  //   const userType = params.get('userType');
  //   const source = params.get('source');
  //   const campaign = params.get('utm_campaign');
  //   const medium = params.get('utm_medium');

  //   // Only send data if parameters exist
  //   if (userType && source && campaign && medium) {
  //     const urlParams = {
  //       userType,
  //       source,
  //       campaign,
  //       medium
  //     };

  //     // Send these parameters to the backend automatically when the page loads
  //     axios.post('http:localhost:8005/save-url-params', urlParams)
  //       .then(response => {
  //         console.log('URL parameters saved successfully:', response.data);
  //       })
  //       .catch(error => {
  //         console.error('Error saving URL parameters:', error);
  //       });
  //   }
  // }, []); 

  useEffect(() => {
    const trackVisit = () => {
      // Check if the visit has already been tracked in this session
      if (!sessionStorage.getItem('visitTracked')) {
        axios.post('https://mail.gpsonline.in/track-visit', {
          timestamp: new Date(),
          userAgent: navigator.userAgent,
          referrer: document.referrer,
          currentPage: window.location.href,
        })
        .then(response => {
          console.log('Visit tracked');
          // Set sessionStorage to mark the visit as tracked
       
        })
        .catch(error => {
          console.error('Error tracking visit:', error);
        });
      }
    };

    // Track visit on initial load
    trackVisit();

    // Track visit on route change (for single-page apps)
    const handlePopState = () => {
      trackVisit();
    };

    // Listen for popstate (browser back/forward)
    window.addEventListener('popstate', handlePopState);

    // Listen for history.pushState or history.replaceState using window.history
    const originalPushState = window.history.pushState;
    window.history.pushState = (...args) => {
      originalPushState.apply(window.history, args);
      trackVisit();
    };

    const originalReplaceState = window.history.replaceState;
    window.history.replaceState = (...args) => {
      originalReplaceState.apply(window.history, args);
      trackVisit();
    };

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  
  return (
    <Router>
    {/* Navbar */}

   <Navbar/>
   <FloatingIcons/>

    {/* Main Content */}
    
 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mobileapps" element={<About />} />
        <Route path="/supported_device" element={<Services />} />
        <Route path="/getting_started" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Plans" element={<Pay/>}/>
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
      </Routes>
   
     <ScrollToTopButton/>
      <Footer/>
    
  </Router>
  );
}

export default App;
