import React, { useState } from 'react';
import img from '../assests/mobile1.png';
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import Storee from './Store';

const devices = [
  // Existing devices
  {
    name: 'Freematics Traccar Edition',
    protocol: 'freematics',
    port: 5170,
  },
  {
    name: 'TK103',
    protocol: 'gps103',
    port: 5001,
   
    
},
// New devices
{
    name: 'TK103-2B',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK104',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK106',
    protocol: '-',
    port: 'clones',
},
{
    name: 'GPS-103',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS-103-A',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TW-MD1101',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS102',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS102B',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS103',
    protocol: '-',
    port: 'clones',
},
{
    name: 'GPS104',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK110',
    protocol: '-',
    port: 'clones',
},
{
    name: '306A',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK-102B',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS105B',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS106',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS107',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS301',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS302',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS303',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS304',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS305',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban GPS306',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'A306',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'GPS303-G',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'Coban 303F',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK303B',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'TK303G',
    protocol: 'gps103',
    port: 5001,
},
{
    name: 'EC-546',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'GT02A',
    protocol: '-',
    port: 'clones',
},
{
    name: 'TT0024',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T1024',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T1080',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T2024',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T2124',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T12',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T4400',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T8800',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T15400',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'TK05',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'TK10',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'TK15',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'Kingneed TK20',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T18',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T18H',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'T16',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'GPS105',
    protocol: '-',
    port: 'clones',
},
{
    name: 'P168',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'P169',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'TK206',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'TK207',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'KS168',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'Kingneed',
    protocol: 'tk103',
    port: 5002,
},
{
    name: 'GL100',
    protocol: 'gl100',
    port: 5003,
},
{
    name: 'GL100M',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL200',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV300N',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GT200',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GT300',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GT500',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL300',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL3000W Series',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL300VC',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL300W Series',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL500',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL505',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GL520',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GT301',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GB100',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GB100MG',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GMT100',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GMT200',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GS100',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV200',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV300',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV300VC',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV300W',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV500',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV500VC',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV50LTA',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV55',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV55 Lite',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV55VC',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV600W',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV65',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV65 Plus',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV75',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV75W',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'Prime AT Plus',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'GV500MAP',
    protocol: 'gl200',
    port: 5004,
},
{
    name: 'Gelix',
    protocol: 't55',
    port: 5005,
},
{
    name: 'Gelix-2',
    protocol: 't55',
    port: 5005,
},
{
    name: 'GPS-911(M)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-900',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-900(R)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-900(M)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-901(B)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-901(C)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-901(D)',
    protocol: 't55',
    port: 5005,
},
{
    name: 'AVL-921',
    protocol: 't55',
    port: 5005,
},
{
    name: 'CradlePoint IBR600',
    protocol: 't55',
    port: 5005,
},
{
    name: 'CradlePoint IBR1100',
    protocol: 't55',
    port: 5005,
},
{
    name: 'Aspicore',
    protocol: 't55',
    port: 5005,
},
{
    name: 'Tracker for Traccar',
    protocol: 't55',
    port: 5005,
},
{
    name: 'MultiConnect rCell',
    protocol: 't55',
    port: 5005,
},
{
    name: 'M2M IP Modem F7114',
    protocol: 't55',
    port: 5005,
},
{
    name: 'Datamax',
    protocol: 't55',
    port: 5005,
},
{
    name: 'MA100-1010',
    protocol: 't55',
    port: 5005,
},
{
    name: 'TK101',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK102',
    protocol: '-',
    port: 'clones',
},
{
    name: 'TK102-2',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK103',
    protocol: '-',
    port: 'clones',
},
{
    name: 'TK103B',
    protocol: '-',
    port: 'clones',
},
{
    name: 'TK103-2',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'XT009',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'XT011',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK201',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK201-2',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK202',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK203',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TK206',
    protocol: '-',
    port: 'clones',
},
{
    name: 'XT107',
    protocol: 'xexun',
    port: 5006,
},
{
    name: 'TZ-AVL02',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL03',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL05',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL08',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL09',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL10',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-GT08',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-GT09',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-VN06',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'AT03',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'AT06',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'AT06+',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'AT07',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'AT09',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'TZ-AVL201',
    protocol: 'totem',
    port: 5007,
},
{
    name: 'Mini MT',
    protocol: 'enfora',
    port: 5008,
},
{
    name: 'Enfora GSM2338',
    protocol: 'enfora',
    port: 5008,
},
{
    name: 'Enfora GSM2448',
    protocol: 'enfora',
    port: 5008,
},
{
    name: 'Enfora MT4000',
    protocol: 'enfora',
    port: 5008,
},
{
    name: 'GT30i',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT60',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT300',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT310',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT400',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT30',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT30X',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PST-AVL01',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT03',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT60',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT300X',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT30',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT-110P',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT-110K',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT-110M',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT-110ES',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT-110ZS',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'AVL-011',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT900',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'P008',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GT 30',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'CT01',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'CT03',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'CT04',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'CT04-R',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'CT04-X',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT600',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'MT01',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'MT02',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT01',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PT03',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT1000',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'GSY007',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'T200',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT310N',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'TK108',
    protocol: '-',
    port: 'clones',
},
{
    name: 'TK228',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OST800',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT900',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT900-R',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT800',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT800-D',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT600-CAM',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'OCT600-SG3',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'TZ-GT01',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'VT600',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten MT05',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten MT05S',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten LT02',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten LT03',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten GT08',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten GT08S',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten GT48',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten MT35',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK510',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK208',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK208S',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK218',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK228',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten TK428',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten PT99',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'Topten PG99',
    protocol: 'meiligao',
    port: 5009,
},
{
    name: 'PG88',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'S88',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'PG22',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'S22',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'VT206',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'PT33',
    protocol: 'trv',
    port: 5010,
},
{
    name: 'ST200',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST210',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST215',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST215I',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST215E',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST240',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST230',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST410',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST900',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST910',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST-910',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'ST-940',
    protocol: 'suntech',
    port: 5011,
},
{
    name: 'Progress 7s',
    protocol: 'progress',
    port: 5012,
},
{
    name: 'H02',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'H-02A',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'H-02B',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'TX-2',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'H-06',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'H08',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GTLT3',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'NT201',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'NT202',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'S31',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK106',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK109',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK110',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK120',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK206',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK208',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK209',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK210',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK310',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK610',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK660',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK670',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK700',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK710',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK800',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK910',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK920',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK930',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK206A',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK206B',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'LK209C',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'MI-G6',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CC830',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CCTR',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CCTR-630',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CCTR-830G',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'AT-18',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GRTQ',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'PT301',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'VT900',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'G91S',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'G-T005',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Incutex TK105',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'RF-V8S',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CCRT809',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'AT-1',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'MT-1',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'CCTR-622G',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Amparos S4',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'RF-16V',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Cantrack G01',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Cantrack G02',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Cantrack G03',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Cantrack G05',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Secumore G05',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'Sinotrack ST-901',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GTRACK4G',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE710',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE800',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'TK909',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE210',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE103',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE209A',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE209B',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE209C',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE109',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'XE208',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GTR-100',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'MV720',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'MV740',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'G900',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'i-Trac MT1',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'i-Trac VT600X',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'FM03E',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'G06L',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'TK100',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GF40',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GF60L',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GF70L',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'GF200LS',
    protocol: 'h02',
    port: 5013,
},
{
    name: 'JT600',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'GP4000',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'GP5000',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'GP6000',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'JT700A',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'JT700B',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'JT700C',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'JT700',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'JT701',
    protocol: 'jt600',
    port: 5014,
},
{
    name: 'HB-A5',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G1C',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'JT705',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'ST-902W',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'CCTR 830-4G',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'CCTR-800G',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'ST-901 A+',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-420 Padlock',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'OBD2 V20',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'TA09',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-300',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'MicTrack B21',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'TK319',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'LT-162',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'DT-700',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'RF-V03',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-310N',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-310P',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-316',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-360N',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-360P',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-508N',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G-666',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'ICAR GPS IK703',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'iMars Micro',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'UM777',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'P60LC',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'YG119',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G08L',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'G09L',
    protocol: 'huabao',
    port: 5015,
},
{
    name: 'V680',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'P10',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'HC207',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'VT810',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'KS168M',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'HC06A',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'TL201',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'TK118',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'PT200',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'PT350',
    protocol: 'v680',
    port: 5016,
},
{
    name: 'TK06A',
    protocol: '-',
    port: 'clones',
},
{
    name: 'Bofan PT03',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT30',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT80',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT300X',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT100',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT200',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT201',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT500',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT502',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT600',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT510',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT520',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT60',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'Bofan PT600X',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'AnioSmart A510',
    protocol: 'pt502',
    port: 5017,
},
{
    name: 'TR-20',
    protocol: 'tr20',
    port: 5018,
},
{
    name: 'Signal S-2115',
    protocol: 'navis',
    port: 5019,
},
{
    name: 'Signal S-2117',
    protocol: 'navis',
    port: 5019,
},
{
    name: 'CH-4713',
    protocol: 'navis',
    port: 5019,
},
{
    name: 'CH-5703',
    protocol: 'navis',
    port: 5019,
},
{
    name: 'MT80',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MT80i',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MT88',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MT90',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'KT90',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MVT100',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MVT340',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MVT380',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MVT600',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'MVT800',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'T1',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'T3',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'TC68',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'TC68S',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'T322',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'T622',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'P99G',
    protocol: 'meitrack',
    port: 5020,
},
{
    name: 'TT8750',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'TT8750+',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'TT9500',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'TT9200',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'TT8850',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'GSM5108',
    protocol: 'skypatrol',
    port: 5021,
},
{
    name: 'GT02',
    protocol: 'gt02',
    port: 5022,
},
{
    name: 'TR02',
    protocol: 'gt02',
    port: 5022,
},
{
    name: 'GT06',
    protocol: '-',
    port: 'clones',
},
{
    name: 'GT06N',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT07',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GT09',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Heacent 908',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT03',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT03A',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT03B',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GS503',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'ET100',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GT100',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GT06D',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GK301',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GK503',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM01',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM08',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT02D',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'IB-GT102',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'CRX1',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox CRX3',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JV200',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'TP06A',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'BW02',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'BW08',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'BW09',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Benway ET300',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'TR06',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JI09',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT300',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'WeTrack 2',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GT230',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'OBD189',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'ZX612',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'S112U',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Seeworld',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G19',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'TW02B',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'EV02',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G19H',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G18',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G19S',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G17O',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'S20',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G30',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM-VG01U',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM-VG02U',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'GPS311A',
    protocol: '-',
    port: 'clones',
},
{
    name: 'JM-VL01',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM-VL02',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM-VL03',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'JM-VL04',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Vjoycar G28',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-01',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-05',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-06',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-07',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-09',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-10',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-11',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Szorchid OC-13',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G109',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'J14',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Chentian OBD',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT710',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox GT800',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Concox OB22',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'Q-Bit',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL22',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL24',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL28',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL42',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL44',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'SL48',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'FM02',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'G900L',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'C32',
    protocol: 'gt06',
    port: 5023,
},
{
    name: 'MT-90',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT-100',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GPT-69',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GT-89',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GT-99',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'XT-007',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GMT-368',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GVT-369',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GVT-390',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GVT-500',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GVT-510',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GMT-368SQ',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'XT7',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'GMT368s',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT60-X',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT-90x',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT-60X',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT300',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT110',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'MT200x',
    protocol: 'megastek',
    port: 5024,
},
{
    name: 'TD230',
    protocol: 'navigil',
    port: 5025,
},
{
    name: 'uTrace03e',
    protocol: 'navigil',
    port: 5025,
},
{
    name: 'GpsGate',
    protocol: 'gpsgate',
    port: 5026,
},
{
    name: 'FMB965',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC920',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM920',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC003',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM003',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB150',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC150',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM150',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1110',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1010',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1120',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1202',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM2100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM2200',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3001',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3200',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3612',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM36M1',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM4100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM4200',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM5300',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM6320',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'GH3000',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3300',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'CT2000E',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM1204',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3612',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM3622',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB900',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB920',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMT100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB001',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB002',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB010',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB020',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB110',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB120',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB122',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB125',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMA110',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMA120',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB202',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB204',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMA202',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMA204',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB630',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB640',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB962',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB964',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'TMT250',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC001',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM001',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC125',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC130',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMC640',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM125',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM130',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMM640',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FM6300',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMU125',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMU126',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMU130',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB130',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB140',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'MSP500',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB003',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'MTB100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'FMB208',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'Damoov Telematics SDK',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'TRB245',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'TRB255',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUT955',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUT956',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUTX09',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUTX11',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUTX12',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUTX14',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'RUTX50',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'Bitrek Series',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'TAT100',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'TAT140',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'GH-5200',
    protocol: 'teltonika',
    port: 5027,
},
{
    name: 'Gruz',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'Personal',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'ZoomBox',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MPU-01',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MPU-01 GLONASS',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MTA-02',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MTA-02-GLONASS',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MTA-02-CAM',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MTA-03',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'MTA-12',
    protocol: 'mta6',
    port: 5028,
},
{
    name: 'TZ-AVL19',
    protocol: 'tzone',
    port: 5029,
},
{
    name: 'TLT-2F',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'V520',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-2H',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-1C',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'V690',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'VSUN3338',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-3A',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'V580',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-1B',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-2K',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-2N',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-1F',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-8A',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-8B',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-3A',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-1D',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-6C',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'TLT-7B',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'AT-12A',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MP10',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MP80',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MP90',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MC300',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT510',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT500',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT510-G',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT530',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT510G',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT600',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MT700',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'MiniFinder Xtreme',
    protocol: 'tlt2h',
    port: 5030,
},
{
    name: 'Syrus GPS',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'E-Track',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Sierra',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Lantrix',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Syrus SY2210',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Lantrix T1700',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Peplink Transit Mini',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Pepwave MAX BR1',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'CradlePoint IBR900',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'CradlePoint IBR950',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Cradlepoint S700',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Microhard',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho C2',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho C3',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho C5',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho Ultralite',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho Spider',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho Spider IoT',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho Smart IoT',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'Rinho Minitrack',
    protocol: 'taip',
    port: 5031,
},
{
    name: 'WondeX VT300',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'WondeX SPT-10',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'TK5000',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'Navixy M7',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'TK5000XL',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'MiniFinder Xtreme One',
    protocol: 'wondex',
    port: 5032,
},
{
    name: 'CelloTrack 6M (IP65)',
    protocol: 'cellocator',
    port: 5033,
},
{
    name: 'CelloTrack IP67',
    protocol: 'cellocator',
    port: 5033,
},
{
    name: 'CelloTrack XT',
    protocol: 'cellocator',
    port: 5033,
},
{
    name: 'Ekas Tracker',
    protocol: 'cellocator',
    port: 5033,
},
{
    name: 'GalileoSky 2.5',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky 4.0',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky 5.0',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky 5.1',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky 7.0',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky 7.0 Lite',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block Lite',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block Optimum',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block Wi-Fi',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block Wi-Fi Hub',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Base Block Iridium',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'GalileoSky Boxfinder',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'Galileosky OBD-II',
    protocol: 'galileo',
    port: 5034,
},
{
    name: 'V-MT001',
    protocol: 'ywt',
    port: 5035,
},
{
    name: 'V208',
    protocol: 'ywt',
    port: 5035,
},
{
    name: 'TK102 Clone',
    protocol: '-',
    port: 'clones',
},
{
    name: 'IntelliTrac P1',
    protocol: 'intellitrac',
    port: 5037,
},
{
    name: 'IntelliTrac X1 Plus',
    protocol: 'intellitrac',
    port: 5037,
},
{
    name: 'IntelliTrac X8',
    protocol: 'intellitrac',
    port: 5037,
},
{
    name: 'Careu Ueco',
    protocol: 'intellitrac',
    port: 5037,
},
{
    name: 'GPSMTA',
    protocol: 'gpsmta',
    port: 5038,
},
{
    name: 'Wialon IPS',
    protocol: 'wialon',
    port: 5039,
},
{
    name: 'MasterKit',
    protocol: 'wialon',
    port: 5039,
},
{
    name: 'MasterKit BM8009',
    protocol: 'wialon',
    port: 5039,
},
{
    name: 'NeoTech TR_1000',
    protocol: 'wialon',
    port: 5039,
},
{
    name: 'Eskort TD-Online',
    protocol: 'wialon',
    port: 5039,
},
{
    name: 'CCTR-620',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-622',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-700',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-800',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-801',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-802',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-803',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-808',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-810',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'CCTR-620+',
    protocol: 'carscop',
    port: 5040,
},
{
    name: 'T-104',
    protocol: 'apel',
    port: 5041,
},
{
    name: 'T-104PRO',
    protocol: 'apel',
    port: 5041,
},
{
    name: 'T-104 GLONASS',
    protocol: 'apel',
    port: 5041,
},
{
    name: 'MP2030A',
    protocol: 'manpower',
    port: 5042,
},
{
    name: 'MP2030B',
    protocol: 'manpower',
    port: 5042,
},
{
    name: 'MP2031A',
    protocol: 'manpower',
    port: 5042,
},
{
    name: 'MP2031B',
    protocol: 'manpower',
    port: 5042,
},
{
    name: 'MP2031C',
    protocol: 'manpower',
    port: 5042,
},
{
    name: 'TR-151',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-151SP',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-203',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-206',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-600',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-600G',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'TR-606B',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'GTR-128',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'GTR-129',
    protocol: 'globalsat',
    port: 5043,
},
{
    name: 'ATrack AT1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AT1Pro',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AT5i',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AU5i',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX5',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AY5i',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AT3',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AT5',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AU5',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AY5',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AT1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX5C',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AK7V',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AS1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AS11',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AK1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AK11',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AK7S',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AL1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AL11',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AL7',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AP1',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AS3',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AU7',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX5',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX7',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX7P',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX9',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'ATrack AX11',
    protocol: 'atrack',
    port: 5044,
},
{
    name: 'PT3000',
    protocol: 'pt3000',
    port: 5045,
},
{
    name: 'FM-Pro3-R',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'FM-Tco3',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'FM-Pro3',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'FM-Eco3',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'Trailer Tracker',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'FM-ECO 4',
    protocol: 'ruptela',
    port: 5046,
},
{
    name: 'T8803',
    protocol: 'topflytech',
    port: 5047,
},
{
    name: 'T8801',
    protocol: 'topflytech',
    port: 5047,
},
{
    name: 'T8901',
    protocol: 'topflytech',
    port: 5047,
},
{
    name: 'StarFinder AIRE',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'StarFinder Lite',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'StarFinder Bus',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'S911 Lola',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'S911 Bracelet Locator',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'S911 Bracelet Locator HC',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'S911 Bracelet Locator ST',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'S911 Personal Locator',
    protocol: 'laipac',
    port: 5048,
},
{
    name: 'A9',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'A11',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'A1 Max',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'A1 Trax',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'A1 M2M',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'A5 GLX',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'Aplicom C-series',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'Aplicom Q-series',
    protocol: 'aplicom',
    port: 5049,
},
{
    name: 'Omega T600',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'TL007',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'TL201',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'TL206',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'TL218',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'VT108',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'VT1081',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'TP-20',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'EQT-20',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'G-TL-020',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'GP106M',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'Toplovo',
    protocol: 'gotop',
    port: 5050,
},
{
    name: 'GC-101',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'CT-24',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'CT-58',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'CT-58A',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'GX-101',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'GS-818',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'MT-101',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'MU-201',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'QG-201',
    protocol: 'sanav',
    port: 5051,
},
{
    name: 'M528',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'M508',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'M518',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'M518S',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'M588N',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'M588S',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'S208',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'S228',
    protocol: 'gator',
    port: 5052,
},
{
    name: 'NR002',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR006',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR008',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR016',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR024',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR028',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'NR032',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'UT01',
    protocol: 'noran',
    port: 5053,
},
{
    name: 'UM02',
    protocol: 'noran',
    port: 5053,
},
];

const SupportedDevice = () => {
  const [search, setSearch] = useState('');
  const [protocol, setProtocol] = useState('');
  const [page, setPage] = useState(1);
  const devicesPerPage = 12;

  const filteredDevices = devices.filter((device) =>
    device.name.toLowerCase().includes(search.toLowerCase()) &&
    (protocol ? device.protocol === protocol : true)
  );

  const paginatedDevices = filteredDevices.slice(
    (page - 1) * devicesPerPage,
    page * devicesPerPage
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleProtocolChange = (e) => {
    setProtocol(e.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const protocols = [...new Set(devices.map((device) => device.protocol))];

  return (
    <div>

        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: 'purple' }}>
            Supported Devices
          </Typography>
          <Typography variant="body1" sx={{ color: '#555', marginTop: 1 }}>
            Find, filter, and manage devices based on the protocol and search criteria.
          </Typography>
          <Typography variant="h6" sx={{ color: '#555', marginTop: 1 }}>
            Server : <a href="https://server.gpsonline.in" target="_blank" rel="noopener noreferrer" 
            style={{
              color: 'purple',
              textDecoration: 'none',
              transition: 'color 0.3s',
              ':hover': {
                color: '#FF8C00'
              }
            }}>
            server.gpsonline.in
            </a>
          </Typography>
        </Box>

        {/* Search Box & Protocol Select */}
      <Grid container spacing={3} marginBottom={3} justifyContent="center" alignItems="center">
        {/* Search Input */}
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Search Devices"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            sx={{
              backgroundColor: '#fafafa',  // Light background for input
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#cccccc',  // Lighter border color
                },
                '&:hover fieldset': {
                  borderColor: '#1E3A8A', // Border color on hover (blue)
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1E3A8A', // Focused state (blue)
                },
              },
              marginTop: '16px',
            }}
          />
        </Grid>

        {/* Protocol Selection */}
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl fullWidth sx={{ marginTop: '16px' }}>
            <InputLabel sx={{ fontSize: '16px', color: '#333' }}>Protocol</InputLabel>
            <Select
              value={protocol}
              label="Protocol"
              onChange={handleProtocolChange}
              sx={{
                backgroundColor: '#fafafa',  // Light background for select box
                borderRadius: '8px',
                '& .MuiSelect-root': {
                  padding: '12px 16px',  // Padding inside the select box
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#cccccc',  // Lighter border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#1E3A8A',  // Border color on hover (blue)
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1E3A8A',  // Focused state (blue)
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {protocols.map((proto) => (
                <MenuItem key={proto} value={proto}>
                  {proto}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Table for Devices */}
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  color: '#FF8C00',
                  fontWeight: '600',
                  fontSize: '16px',
                  borderBottom: '2px solid #FF8C00',
                  padding: '12px 24px',
                  backgroundColor: '#fffae6',
                }}
              >
                <Typography variant="subtitle1">Device Name</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: '#FF8C00',
                  fontWeight: '600',
                  fontSize: '16px',
                  borderBottom: '2px solid #FF8C00',
                  padding: '12px 24px',
                  backgroundColor: '#fffae6',
                }}
              >
                <Typography variant="subtitle1">Protocol</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: '#FF8C00',
                  fontWeight: '600',
                  fontSize: '16px',
                  borderBottom: '2px solid #FF8C00',
                  padding: '12px 24px',
                  backgroundColor: '#fffae6',
                }}
              >
                <Typography variant="subtitle1">Port</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDevices.map((device, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:hover': {
                    backgroundColor: '#F4F4F4',
                    cursor: 'pointer',
                  },
                  borderBottom: '1px solid #E0E0E0',
                }}
              >
                <TableCell
                  sx={{
                    color: '#1E3A8A',
                    padding: '16px 24px',
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'transparent', // Alternate row color
                  }}
                >
                  {device.name}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#1E3A8A',
                    padding: '16px 24px',
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'transparent',
                  }}
                >
                  {device.protocol}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#1E3A8A',
                    padding: '16px 24px',
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'transparent',
                  }}
                >
                  {device.port}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination at the bottom */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Pagination
          count={Math.ceil(filteredDevices.length / devicesPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{ marginTop: '10px' }}
        />
      </Box>

      <Storee />
    </div>
  );
};

export default SupportedDevice;
