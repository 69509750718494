import React, { useState } from 'react';
import { Box, Grid, Typography, Link, IconButton, Divider, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';

import logo from '../assests/gpsonline-192.png'

// Styled Footer Wrapper
const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#F8F8F8', // Lighter background color
  color: '#333', // Dark text for better readability
  padding: theme.spacing(6, 3),
  textAlign: 'center',
  marginTop: 'auto',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

// Manufacturers Section Wrapper
const ManufacturersWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(4, 3),
  marginBottom: theme.spacing(4),
  borderRadius: '10px',
  textAlign: 'center',
}));

// Logo Styling
const Logo = styled('img')({
  maxWidth: '180px',
  marginBottom: '24px',
  [window.innerWidth < 600 ? 'maxWidth' : '']: '150px', // Adjust logo size for small screens
});

// Social Media Icons with Hover Effect
const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: '#333',
  marginRight: '12px',
  '&:hover': {
    color: '#f39c12', // Hover color
    transform: 'scale(1.1)', // Slight scale on hover
    transition: 'all 0.3s ease',
  },
}));

// Styled Divider
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  backgroundColor: '#e0e0e0', // Light gray divider
}));

// Contact Button Styling
const ContactButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f39c12',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#e67e22',
  },
}));

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Name:', name, 'Email:', email);
    setName('');
    setEmail('');
  };

  return (
    <FooterWrapper>
    
      

      <Grid container spacing={4} justifyContent="space-between" alignItems="center">
        {/* Left Side: Logo and Text */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <img src={logo} alt="Mirusoft Consultancy Logo" style={{ height: '70px', width: '70px', marginBottom: '10px',  verticalAlign: 'middle' }} />
           
          </Box>
          <Typography variant="body2" sx={{ marginBottom: 2, fontSize: '14px' }}>
            &copy; {new Date().getFullYear()} Mirusoft Consultancy (OPC) Private Limited. All rights reserved.
          </Typography>
        </Grid>
     
      </Grid>

    
      <StyledDivider />

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
        offers reliable and real-time tracking solutions for vehicles, assets, and personal devices. Our advanced technology ensures precise location data, real-time monitoring, and seamless integration with mobile apps and web platforms. Whether you're managing a fleet, tracking valuable assets, or keeping an eye on loved ones, Mirusoft provides the tools you need to stay connected and secure.
        </Typography>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
