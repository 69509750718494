import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { jwtDecode } from "jwt-decode";
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Storee from './Store';

import {
  Button,
  TextField,
  Grid,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from "@mui/material";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch
  } = useForm();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [showOTP, setShowOTP] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [formDataTemp, setFormDataTemp] = useState(null);
  const [currentSubject, setCurrentSubject] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isEmailValid, setIsEmailValid] = useState(false);

  // Check for existing token on component mount
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserToken(token);
        setUserEmail(decoded.email);
      } catch (error) {
        localStorage.removeItem('userToken');
      }
    }
  }, []);

  const { selectedPlan = '', selectedVehicles = [] } = location.state || {};

  // Add default options
  const defaultVehicleOptions = {
    basic: {
      "1 vehicle": "500/year",
      "5 vehicles": "2400/year",
      "10 vehicles": "4500/year",
      "25 vehicles": "10500/year",
      "50 vehicles": "20000/year",
    },
    pro: {
      "1 vehicle": "600/year",
      "5 vehicles": "2900/year",
      "10 vehicles": "5500/year",
      "25 vehicles": "13000/year",
      "50 vehicles": "24000/year",
    }
  };

  const [localSelection, setLocalSelection] = useState({
    plan: '',
    vehicles: []
  });

  useEffect(() => {
    // Handle plan selection
    if (selectedPlan && typeof selectedPlan === 'string') {
      const planType = selectedPlan.toLowerCase().includes('basic') ? 'basic' : 'pro';
      setLocalSelection(prev => ({ ...prev, plan: planType }));
      const initialSubject = `Inquiry about ${selectedPlan}`;
      setCurrentSubject(initialSubject);
      reset({ 
        subject: initialSubject
      });
    }

    // Handle vehicle selection
    if (Array.isArray(selectedVehicles) && selectedVehicles.length > 0) {
      setLocalSelection(prev => ({ ...prev, vehicles: selectedVehicles }));
      setValue("vehicles", selectedVehicles.map(vehicle => vehicle.vehicle));
    }
  }, [selectedPlan, selectedVehicles, reset, setValue]);

  // Modified onSubmit handler
  const onSubmit = async (data) => {
    if (!userToken) {
      setFormDataTemp(data);
      setShowOTP(true);
      return;
    }
    
    await sendEmail(data);
  };

  // New function to handle email sending
  const sendEmail = async (data) => {
    setLoading(true);
    try {
      const response = await fetch("https://mail.gpsonline.in/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify({
          ...data,
          selectedPlan,
          selectedVehicles,
        }),
      });

      if (response.ok) {
        setStatus("Your message has been sent successfully!");
        reset({
          name: '',
          email: '',
          phone: '',
          message: '',
          subject: currentSubject, // Preserve the subject
          vehicles: localSelection.vehicles.map(v => v.vehicle)
        });
      } else {
        setStatus("Something went wrong, please try again later.");
      }
    } catch (error) {
      setStatus("An error occurred while sending your message.");
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP verification success
  const handleVerifySuccess = (token) => {
    const decoded = jwtDecode(token);
    setUserToken(token);
    setUserEmail(decoded.email);
    localStorage.setItem('userToken', token);
    setShowOTP(false);
    
    if (formDataTemp) {
      sendEmail(formDataTemp);
      setFormDataTemp(null);
    }
  };

  // Replace the existing vehicle selection field with this enhanced version
  const VehicleSelectionField = () => (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel>Select Vehicles</InputLabel>
        <Select
          {...register("vehicles", { required: "Please select vehicles" })}
          error={!!errors.vehicles}
          value={localSelection.vehicles.map(v => v.vehicle) || []}
          onChange={(e) => {
            const selected = e.target.value;
            const planType = localSelection.plan || (selectedPlan?.toLowerCase().includes('basic') ? 'basic' : 'pro');
            const vehicleInfo = {
              vehicle: selected,
              price: defaultVehicleOptions[planType][selected]
            };
            setLocalSelection(prev => ({
              ...prev,
              vehicles: [vehicleInfo]
            }));
            setValue("vehicles", [selected]);
          }}
        >
          {Object.entries(defaultVehicleOptions[localSelection.plan || 'basic']).map(([vehicle, price]) => (
            <MenuItem key={vehicle} value={vehicle}>
              {vehicle} - ₹{price}
            </MenuItem>
          ))}
        </Select>
        {errors.vehicles && (
          <Typography color="error" variant="caption">
            {errors.vehicles.message}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );

  // Replace the existing subject field with this enhanced version
  const SubjectField = () => (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel>Subject</InputLabel>
        <Select
          {...register("subject", { required: "Please select a subject" })}
          error={!!errors.subject}
          value={currentSubject || ''}
          onChange={(e) => {
            setCurrentSubject(e.target.value);
            setValue("subject", e.target.value);
          }}
        >
          <MenuItem value="Inquiry about Basic Plan">Basic Plan Inquiry</MenuItem>
          <MenuItem value="Inquiry about Pro Plan">Pro Plan Inquiry</MenuItem>
          <MenuItem value="Custom Inquiry">Custom Inquiry</MenuItem>
        </Select>
        {errors.subject && (
          <Typography color="error" variant="caption">
            {errors.subject.message}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );

  // Add user profile section at the top
  const UserProfile = () => userEmail && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        p: 2,
        bgcolor: '#f5f5f5',
        borderRadius: 1,
        mb: 3
      }}
    >
      <Avatar sx={{ bgcolor: '#1976d2' }}>
        {userEmail.charAt(0).toUpperCase()}
      </Avatar>
      <Typography variant="subtitle1">Hello,{userEmail}</Typography>
    </Box>
  );

  // Function to validate email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // Handle OTP send
  const handleSendOtp = async () => {
    const email = watch("email");
    setLoading(true);
    try {
      const response = await axios.post("https://mail.gpsonline.in/send-otp", { email });
      if (response.status === 200) {
        setOtpSent(true);
        setStatus("OTP sent successfully!");
      }
    } catch (error) {
      setStatus("Failed to send OTP.");
    }
    setLoading(false);
  };

  // Handle OTP verification
  const handleVerifyOtp = async () => {
    const email = watch("email");
    const otpString = otp.join('');
    setLoading(true);
    try {
      const response = await axios.post("https://mail.gpsonline.in/verify-otp", { 
        email, 
        otp: otpString 
      });
      if (response.status === 200) {
        setOtpVerified(true);
        setUserToken(response.data.token);
        localStorage.setItem('userToken', response.data.token);
        setStatus("OTP verified successfully!");
      }
    } catch (error) {
      setStatus("Invalid OTP.");
    }
    setLoading(false);
  };

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/\d/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  return (
    <div style={{ padding: "40px", background: "white" }}>
    
      <UserProfile />

      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
          color: "#2c3e50",
          fontSize: "32px",
          textAlign: "center",
        }}
      >
        Contact Us
      </Typography>

     
      <Typography
        variant="body1"
        align="center"
        sx={{
          marginBottom: "40px",
          color: "#7f8c8d",
          fontSize: "18px",
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div>
          <EmailIcon sx={{ marginRight: 1 }} />
          <a href="mailto:mirusoft24@gmail.com" style={{textDecoration:'none',color:'purple'}}>mirusoft24@gmail.com</a>
        </div>
        <div>
          <PhoneIcon sx={{ marginRight: 1 }} />
        <a href="tel:+919597088261" style={{textDecoration:'none',color:'purple'}}>+91 9597088261</a>
        </div>
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {/* Name Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              {...register("name", {
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters long",
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "Name can only contain letters and spaces",
                },
                maxLength: {
                  value: 50,
                  message: "Name cannot exceed 50 characters",
                },
                onChange: (e) => validateEmail(watch("email"))
              })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          </Grid>

          {/* Email Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Enter a valid email address",
                },
                maxLength: {
                  value: 100,
                  message: "Email cannot exceed 100 characters",
                },
                onChange: (e) => validateEmail(e.target.value)
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          </Grid>

          {/* Send OTP Button */}
          {!otpSent && isEmailValid && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendOtp}
                disabled={loading}
              >
                Send OTP
              </Button>
            </Grid>
          )}

          {/* OTP Input Fields */}
          {otpSent && !otpVerified && (
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <TextField
                    key={index}
                    id={`otp-input-${index}`}
                    value={otp[index]}
                    onChange={(e) => handleOtpChange(e, index)}
                    variant="outlined"
                    sx={{ width: '50px' }}
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  />
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerifyOtp}
                  disabled={loading || otp.join('').length !== 6}
                >
                  Verify OTP
                </Button>
              </Box>
            </Grid>
          )}

          {/* Show all other fields in disabled state before verification */}
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              disabled={!otpVerified}
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10,15}$/,
                  message: "Enter a valid phone number with 10-15 digits",
                },
                validate: {
                  noConsecutive: (value) =>
                    !/(.)\1\1/.test(value) || "Phone number cannot have more than two consecutive identical digits",
                },
              })}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Subject</InputLabel>
              <Select
                {...register("subject", { required: "Please select a subject" })}
                error={!!errors.subject}
                value={currentSubject || ''}
                disabled={!otpVerified}
                onChange={(e) => {
                  setCurrentSubject(e.target.value);
                  setValue("subject", e.target.value);
                }}
              >
                <MenuItem value="Inquiry about Basic Plan">Basic Plan Inquiry</MenuItem>
                <MenuItem value="Inquiry about Pro Plan">Pro Plan Inquiry</MenuItem>
                <MenuItem value="Custom Inquiry">Custom Inquiry</MenuItem>
              </Select>
              {errors.subject && (
                <Typography color="error" variant="caption">
                  {errors.subject.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Vehicles</InputLabel>
              <Select
                {...register("vehicles", { required: "Please select vehicles" })}
                error={!!errors.vehicles}
                value={localSelection.vehicles.map(v => v.vehicle) || []}
                disabled={!otpVerified}
                onChange={(e) => {
                  const selected = e.target.value;
                  const planType = localSelection.plan || (selectedPlan?.toLowerCase().includes('basic') ? 'basic' : 'pro');
                  const vehicleInfo = {
                    vehicle: selected,
                    price: defaultVehicleOptions[planType][selected]
                  };
                  setLocalSelection(prev => ({
                    ...prev,
                    vehicles: [vehicleInfo]
                  }));
                  setValue("vehicles", [selected]);
                }}
              >
                {Object.entries(defaultVehicleOptions[localSelection.plan || 'basic']).map(([vehicle, price]) => (
                  <MenuItem key={vehicle} value={vehicle}>
                    {vehicle} - ₹{price}
                  </MenuItem>
                ))}
              </Select>
              {errors.vehicles && (
                <Typography color="error" variant="caption">
                  {errors.vehicles.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* New Message Field */}
          <Grid item xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              disabled={!otpVerified}
              {...register("message", {
                minLength: {
                  value: 10,
                  message: "Message must be at least 10 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Message cannot exceed 500 characters",
                },
              })}
              error={!!errors.message}
              helperText={errors.message ? errors.message.message : ""}
            />
          </Grid>

          {/* Verification Status */}
          {otpVerified && (
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, color: 'success.main' }}>
                <CheckCircleIcon color="success" />
                <Typography color="success.main">Verified Successfully</Typography>
              </Box>
            </Grid>
          )}

          {/* Submit Button */}
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !otpVerified}
              sx={{ width: "200px", height: "45px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>


      <Box sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
        If you want bulk vehicle tracking, like for retailers or institutions such as schools and college buses, contact us <br />
          Email: <a href="mailto:mirusoft24@gmail.com" style={
            {textDecoration:'none',color:'purple'}
          
          }>mirusoft24@gmail.com</a> <br />
           Phone:  <a href="tel:919597088261"
           style={{
            textDecoration:'none',color:'purple'
           }}> +91 9597088261</a>
        </Typography>
      </Box>

      <Snackbar
        open={status !== ""}
        autoHideDuration={6000}
        onClose={() => setStatus("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setStatus("")}
          severity={status.includes("success") ? "success" : "error"}
          sx={{ width: "100%" }}
        >
           {status}
        </Alert>
         
       
      </Snackbar>

      <Storee/>

    </div>
  );
};

export default Contact;
