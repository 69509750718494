import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText, Divider, useMediaQuery, Grid } from '@mui/material';
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import BuildIcon from '@mui/icons-material/Build';
import BlogIcon from '@mui/icons-material/Article';
import PhoneIcon from '@mui/icons-material/Phone';
import PlansIcon from '@mui/icons-material/Payment';
import { fontFamily, styled } from '@mui/system';
import logo from '../assests/gpsonline-192.png';
import MIRUSlogo from '../assests/icon-512.png';
import SecurityIcon from "@mui/icons-material/Security";


const NavbarLink = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  margin: '0 20px',
  fontWeight: '500',
  fontSize: '16px',
  padding: '10px 15px',
  borderRadius: '5px',
  color: 'purple', 
  position: 'relative', 
  transition: 'all 0.3s ease-in-out',
  whiteSpace:"nowrap",
  '&.active': {
    color: 'purple', 
    fontWeight: 'bold',
  },
  '&:hover': {
    color: 'purple',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '3px',
    backgroundColor: 'purple',
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease-in-out',
    transformOrigin: 'bottom right',
  },
  '&.active:after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      boxShadow: 'none', // Removed shadow effect on hover
    },
  },
}));

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:1000px)'); // Check if screen width is mobile size

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerLinks = (
    <List sx={{ padding: 0 }}>
      <ListItem 
        button 
        component={NavLink} 
        to="/" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/'} // Updated line
      >
        <HomeIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="Home" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem 
        button 
        component={NavLink} 
        to="/mobileapps" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/mobileapps'} // Updated line
      >
        <InfoIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="Mobile Apps" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem 
        button 
        component={NavLink} 
        to="/supported_device" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/supported_device'} // Updated line
      >
        <BuildIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="Supported device" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem 
        button 
        component={NavLink} 
        to="/getting_started" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/getting_started'} // Updated line
      >
        <BlogIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="Getting Started" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem 
        button 
        component={NavLink} 
        to="/contact" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/contact'} // Updated line
      >
        <PhoneIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="Contact" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem 
        button 
        component={NavLink} 
        to="/Plans" 
        onClick={toggleDrawer} 
        selected={window.location.pathname === '/Plans'} // Updated line
      >
        <PlansIcon sx={{ marginRight: 1, color: 'purple' }} />
        <ListItemText primary="plans" sx={{ color: 'purple' }} />
      </ListItem>
      <Divider />
      <ListItem
      button
      component={NavLink}
      to="/privacy"
      onClick={toggleDrawer}
      selected={window.location.pathname === '/privacy'}
    >
      <SecurityIcon sx={{ marginRight: 1, color: 'purple' }} />
      <ListItemText primary="Privacy Policy" sx={{ color: 'purple' }} />
    </ListItem>
      <ListItem 
        button 
        component={NavLink} 
        to="https://mirusoft.in/" 
        onClick={toggleDrawer}
      >
        <img src={MIRUSlogo} alt="Mirusoft Consultancy Logo" style={{ height: '35px', width: '35px', marginBottom: '3px', marginRight: 1 }} />
        Mirusoft
      </ListItem>
    </List>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#fff', // White background for both views
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for desktop
        height: isMobile ? '90px' : '100px', // Increased height for both mobile and desktop
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px', minHeight: isMobile ? '90px' : '100px' }}>
        {/* Left Side: Logo and Name */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={logo}
            alt="Mirusoft Consultancy Logo"
            style={{ 
              height: '70px', 
              width: '70px', 
              verticalAlign: 'middle', 
              position: 'relative', 
              zIndex: 1 
            }}
          />
          <span
            style={{
              zIndex: 2,
              color: 'purple',
              fontSize: "14px",
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              fontFamily: 'Roboto' + ', sans-serif',
              marginTop: '2px'
            }}
          >
            Mirusoft Product
          </span>
        </Box>

        {/* Mobile Hamburger Menu */}
        {isMobile ? (
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon sx={{ color: '#34495e' }} />
          </IconButton>
        ) : (
            // Desktop Navbar Links with icons
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NavbarLink
              variant=""
              href="https://mirusoft.in/"
            >
              <img src={MIRUSlogo} alt="Mirusoft Consultancy Logo" style={{ height: '35px', width: '35px', marginBottom: '3px', marginLeft: '50px', verticalAlign: 'Middle' }} />
              Mirusoft
            </NavbarLink>
            
            <NavbarLink 
              component={NavLink} 
              to="/" 
              end 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <HomeIcon sx={{ marginRight: 1 }} />
              Home
            </NavbarLink>
            <NavbarLink 
              component={NavLink} 
              to="/mobileapps" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <InfoIcon sx={{ marginRight: 1 }} />
              Mobile Apps
            </NavbarLink>
            <NavbarLink 
              component={NavLink} 
              to="/supported_device" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <BuildIcon sx={{ marginRight: 1 }} />
              Supported device
            </NavbarLink>
            <NavbarLink 
              component={NavLink} 
              to="/getting_started" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <BlogIcon sx={{ marginRight: 1 }} />
              Getting Started
            </NavbarLink>
            <NavbarLink 
              component={NavLink} 
              to="/contact" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <PhoneIcon sx={{ marginRight: 1 }} />
              Contact
            </NavbarLink>
            <NavbarLink 
              component={NavLink} 
              to="/Plans" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <PlansIcon sx={{ marginRight: 1 }} />
              Plans
            </NavbarLink>
            <NavbarLink
              component={NavLink}
              to="/privacy"
              className={({ isActive }) => (isActive ? 'active' : '')}>
              <SecurityIcon sx={{ marginRight: 1 }} />
              Privacy Policy

              </NavbarLink>
            </Box>
          )}
          </Toolbar>

          {/* Mobile Drawer for Navigation with smooth transition */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          transition: 'transform 0.3s ease-in-out',
          '& .MuiDrawer-paper': {
            transition: 'transform 0.3s ease-in-out',
            transform: drawerOpen ? 'translateX(0)' : 'translateX(100%)',
            backgroundColor: '#fff',
            padding: '20px',
          },
        }}
      >
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer}>
          {drawerLinks}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;