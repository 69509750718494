import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  // Detect scroll position
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowButton(true); // Show button when scrolled down 200px
    } else {
      setShowButton(false); // Hide button when at the top
    }
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll effect
    });
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <Fab
          color="primary"
          aria-label="scroll to top"
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            zIndex: 1000, // Ensures the button is on top
          }}
        >
          <ArrowUpward />
        </Fab>
      )}
    </>
  );
};

export default ScrollToTopButton;
