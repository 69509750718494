import React from "react";
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h3" fontWeight="bold" color="primary">
          Privacy Policy
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Your privacy is important to us. This policy explains how we handle your data.
        </Typography>
      </Box>

      <Box sx={{ p: 3, bgcolor: "#f9f9f9", borderRadius: 2, boxShadow: 2 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Information We Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Personal Information"
              secondary="We collect details such as name, email, and payment info when you sign up."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Usage Information"
              secondary="We gather non-personal details like IP address, browser type, and device info."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Cookies"
              secondary="Cookies help us improve your browsing experience. You can disable them anytime."
            />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#f4f4f4", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          How We Use Your Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Providing Services" secondary="Used for delivering requested services, including GPS tracking." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Improving Experience" secondary="We analyze data to enhance our website and services." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Communication" secondary="We may send you updates, which you can opt out of anytime." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#e0f7fa", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Sharing Your Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Third-Party Services" secondary="Data is shared with trusted providers like payment processors." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Legal Compliance" secondary="Data may be disclosed if required by law." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#fff3e0", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Data Security
        </Typography>
        <Typography variant="body1">
          We use security measures to protect your data. However, no method of transmission over the internet is 100% secure.
        </Typography>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#e3f2fd", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Google Analytics
        </Typography>
        <Typography variant="body1">
          We use Google Analytics to track website activity and improve user experience.
        </Typography>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#fce4ec", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Children's Privacy
        </Typography>
        <Typography variant="body1">
          Our services are not intended for children under 13, and we do not knowingly collect their data.
        </Typography>
      </Box>

      <Box sx={{ p: 3, mt: 3, bgcolor: "#eeeeee", borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h5" fontWeight="bold" color="secondary" gutterBottom>
          Changes to This Policy
        </Typography>
        <Typography variant="body1">
          We may update this policy. Changes take effect immediately upon posting.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center", mt: 4, p: 2, bgcolor: "#212121", color: "#ffffff", borderRadius: 2 }}>
        <Typography variant="h6">Contact Us</Typography>
        <Typography variant="body2">If you have any questions, email us at</Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
          rajesh@mirusoft.in
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
