import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import './InitialOfferBanner.css'; // Import the CSS file
import { purple } from '@mui/material/colors';

const InitialOfferBanner = () => {
  const [showPopup, setShowPopup] = useState(false);  // Start as false, we'll decide in useEffect
  const [showBanner, setShowBanner] = useState(true); // Always show the banner

  useEffect(() => {
    // Run confetti once when component is mounted
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });

    // Check if the user has already visited
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    if (!hasVisited) {
      setShowPopup(true); // Show the popup only if the user is visiting for the first time
      localStorage.setItem('hasVisitedBefore', 'true'); // Set flag in localStorage
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowBanner(true); // Ensure the banner is shown when popup is closed
  };

  return (
    <>
      {/* Popup Modal */}
      {showPopup && (
        <div className="popup-modal">
          <div className="popup-content">
            {/* Close Button */}
            <button
              onClick={handleClosePopup}
              className="close-button"
              aria-label="Close Popup"
            >
              ×
            </button>

            {/* Celebration Emoji */}
            <div className="celebration-emoji">🎉</div>

            {/* Popup Content */}
            <h2 className="popup-title">
              launching offer!
              45 Days FREE Trial!
            </h2>

            <p className="popup-description" style={{color:purple}}>
              Get exclusive access to all our features with no obligations. Start today and experience the full
              potential of our platform. Don't miss out!
            </p>

            <a
              href="https://app.gpsonline.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="start-now-button"
            >
              Start Now
            </a>
          </div>
        </div>
      )}

      {/* Top-Right Banner */}
      {showBanner && (
        <div className="top-right-banner">
          <div className="celebration-emoji">🎉</div>

          <span className="banner-text">
            launching offer!
            45 Days FREE Trial!
          </span>

          <a
            href="https://app.gpsonline.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="banner-arrow"
            aria-label="Start Now"
          >
            →
          </a>
        </div>
      )}

     
    </>
  );
};

export default InitialOfferBanner;
